import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid2,
  TextField,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Link,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TableHeadRow, {
  StyledTableCell,
  StyledTableRow,
} from "../shared/tableui/TableHead";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { createQueryString } from "../shared/function/AllFunction";
import { apiGetMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoute";
import { useDashboardContext } from "./contexts/ForDashboard";
import moment from "moment/moment";
import { toast } from "react-toastify";
import TableRowsLoader from "./TableRowsLoader";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/Info";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const Dashboard = () => {
  const {
    setSelectedSSO,
    setSelectedTech,
    selectedSSO,
    selectedTech,
    setLoading,
    sidePanelOpen,
    setSidePanelOpen,
    lastClickedCategory,
    selectedValue,
  } = useDashboardContext();
  const navigate = useNavigate();
  const headCells = [
    { id: "Reference", label: "Reference No." },
    { id: "Type", label: "Type" },
    { id: "Patent_Owner", label: "Patent Owner" },
    { id: "sso", label: "SSO's" },
    { id: "tech_domain", label: "Tech Domain" },
    { id: "Declaration", label: "Declaration Date" },
    { id: "actions", label: "Action" },
  ];
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataFiltered, setDataFiltered] = useState({ result: [] });
  const [tableListing, setTableListing] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [newQueryObject, setNewQueryObject] = useState({});
  const [IPRD_REFERENCE, setIPRD_REFERENCE] = useState([]);
  const [PATENT_OWNER, setPATENT_OWNER] = useState([]);
  const [Publication_Number, setPublication_Number] = useState([]);
  const [Sub_Technology, setSub_Technology] = useState([]);
  const [attribute_name, setAttribute_name] = useState("");
  const [STANDARD, setSTANDARD] = useState([]);
  const [Application_Number, setApplication_Number] = useState([]);
  const [Inventor, setInventor] = useState([]);
  const [Patent_Number, setPATENT_Number] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  // const [dynamicLimit, setDynamicLimit] = useState(10);
  const [data, setData] = useState({
    STANDARD_SET: [],
    PATENT_OWNER: [],
    IPRD_REFERENCE: [],
    PATENT_NUM: [],
    STANDARD: [],
    Sub_Tech: [],
    DATE_FROM: null,
    DATE_TO: null,
    TECH: [],
    Application_Number: [],
    Publication_Number: [],
    Current_Assignee: [],
    Inventor: [],
  });
  console.log(selectedValue, "selectedValueselectedValue");
  const [inputValue, setInputValue] = useState("");
  const handleDateChange = (name) => (newValue) => {
    if (
      name === "DATE_TO" &&
      data.DATE_FROM &&
      newValue.isBefore(data.DATE_FROM)
    ) {
      toast.error("End date cannot be lesser than start date");
      return;
    }

    setData((prevData) => ({
      ...prevData,
      [name]: newValue ? dayjs(newValue) : null,
    }));
  };
  const handleSearch = async () => {
    setTableListing(true);
    setIsLoading(true);
    setSidePanelOpen(false);
    try {
      let queryObject = {
        select_all:
          selectedSSO?.length === 15 && lastClickedCategory == "SSO"
            ? "True" // If condition is true, set select_all to true
            : null,
        STANDARD_SET:
          selectedSSO?.length !== 15 && lastClickedCategory == "SSO"
            ? selectedSSO
            : null,
        PATENT_OWNER: data?.PATENT_OWNER || null,
        IPRD_REFERENCE: data?.IPRD_REFERENCE,
        PATENT_NUM: data?.PATENT_NUM || null,
        STANDARD: data?.STANDARD || null,
        Sub_Tech: data?.Sub_Tech || null,
        Application_Number: data?.Application_Number || null,
        Current_Assignee: data?.Current_Assignee || null,
        Inventor: data?.Inventor || null,
        Publication_Number: data?.Publication_Number || null,
        DATE_FROM:
          (data?.DATE_FROM &&
            moment(new Date(data?.DATE_FROM)).format("YYYY-MM-DD")) ||
          null,
        DATE_TO:
          (data?.DATE_TO &&
            moment(new Date(data?.DATE_TO)).format("YYYY-MM-DD")) ||
          null,
        TECH:
          selectedTech?.length > 0 && lastClickedCategory == "Technology"
            ? selectedTech
            : null || [],
      };
      const qryStr = createQueryString(queryObject);
      setNewQueryObject(qryStr);
      localStorage.setItem("setQuery", qryStr);
      const response = await apiGetMethod(
        `${allApiUrl.FIRST_SEARCH_DASHOARD}${qryStr}`
      );
      setDataFiltered(response);
      setIsLoading(false);
      // setSidePanelOpen(false);
    } catch (err) {
      console.log(err);
      setDataFiltered({ result: [] });
      setIsLoading(false);
      setSidePanelOpen(false);
    }
  };

  // useEffect(() => {
  //   if (tableListing) {
  //     handleSearch();
  //   }
  // }, [tableListing]);
  const fetchResponseData = async (
    page,
    attribute_name,
    limit = 10,
    keyword = ""
  ) => {
    setDataLoading(true);
    try {
      let offset = page * limit;
      let queryObject = {
        attribute_name: attribute_name,
        limit: 10,
        offset: offset,
        search: keyword,
      };
      const qryStr = createQueryString(queryObject);
      const response = await apiGetMethod(
        `${allApiUrl.SEARCH_ATTRIBUTE_DATA}${qryStr}`
      );
      const newItems = response?.[attribute_name] || [];
      console.log(newItems, "newItemsnewItems");
      if (newItems.length > 0) {
        if (attribute_name == "IPRD_REFERENCE") {
          setIPRD_REFERENCE((prev) => [...prev, ...newItems]);
        }
        if (attribute_name == "RECOMMENDATION") {
          setSTANDARD((prev) => [...prev, ...newItems]);
        }
        if (attribute_name == "Sub_Technology") {
          setSub_Technology((prev) => [...prev, ...newItems]);
        }
        if (attribute_name == "Application_Number") {
          setApplication_Number((prev) => [...prev, ...newItems]);
        }
        if (attribute_name == "Publication_Number") {
          setPublication_Number((prev) => [...prev, ...newItems]);
        }
        if (attribute_name == "PATENT_OWNER") {
          setPATENT_OWNER((prev) => [...prev, ...newItems]);
        }
        if (attribute_name == "Inventor") {
          setInventor((prev) => [...prev, ...newItems]);
        }
        if (attribute_name == "Patent_Number") {
          setPATENT_Number((prev) => [...prev, ...newItems]);
        }
      } else {
        setHasMore(false);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
      setDataLoading(false);
    }
  };
  const [page, setPage] = useState(0);
  const handleScroll = (event, attributeName, keyword = "") => {
    const listboxNode = event.target;
    const tolerance = 2;

    const bottom =
      Math.abs(
        listboxNode.scrollHeight -
          (listboxNode.scrollTop + listboxNode.clientHeight)
      ) <= tolerance;

    if (bottom && hasMore) {
      const newPage = page + 1;
      setPage(newPage);
      fetchResponseData(newPage, attributeName, 10, keyword);
    }
  };
  const handleAutocompleteFocus = (attributeName) => {
    setAttribute_name(attributeName);
    setPage(0);
    setHasMore(true);
    fetchResponseData(0, attributeName, 10);
  };
  useEffect(() => {
    if (attribute_name !== "") {
      fetchResponseData(page, attribute_name);
    }
  }, []);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleReset = () => {
    setData({
      STANDARD_SET: [],
      PATENT_OWNER: [],
      IPRD_REFERENCE: [],
      PATENT_NUM: "",
      STANDARD: [],
      Sub_Tech: [],
      DATE_FROM: null,
      DATE_TO: null,
      TECH: [],
      Application_Number: [],
      Publication_Number: [],
      Inventor: [],
    });
    setCurrentPage(0);
    setTableListing(false);
    setSelectedSSO([]);
    setSelectedTech([]);
  };
  const indexOfLastResult = (currentPage + 1) * rowsPerPage;
  const indexOfFirstResult = indexOfLastResult - rowsPerPage;
  const currentResults =
    dataFiltered?.result?.slice(indexOfFirstResult, indexOfLastResult) || [];
  const handleInputKeywordSearch = async (attributeName, keyword) => {
    setDataLoading(true);
    setPage(0); // Reset page to 0 when searching
    setHasMore(true);
    fetchResponseData(0, attributeName, 10, keyword);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isloading && (
        <Box component="div" className="loaderPosition">
          <CircularProgress sx={{ color: "#0B3E68" }} />
        </Box>
      )}
      <Card sx={{ maxWidth: "xl", margin: "auto", backgroundColor: "#EEF7FF" }}>
        <CardContent>
          {tableListing === true && (
            <Box sx={{ flexGrow: 1, p: 3 }}>
              <Grid2 container spacing={3}>
                <Grid2 size={4}>
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        // title={`STANDARD SETTING ORGANIZATION :${dataFiltered?.count?.cat_count?.SSO}/${dataFiltered?.count?.total}`}
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <span className="card-text">SSO</span>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <b>STANDARD SETTING ORGANIZATION</b>
                              <>
                                <br />
                                <b>
                                  {dataFiltered?.count?.cat_count?.SSO
                                    ? dataFiltered?.count?.cat_count?.SSO
                                    : 0}
                                  /{dataFiltered?.count?.total ? 15 : 0}
                                </b>
                              </>
                            </React.Fragment>
                          }
                        >
                          {" "}
                          <InfoIcon />
                        </Tooltip>
                      </Typography>

                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                          className="card-text"
                        >
                          {dataFiltered?.count?.cat_count?.SSO || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                          className="card-text"
                        >
                          /{dataFiltered?.count?.total ? 15 : 0 || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        // title={`STANDARD :${dataFiltered?.count?.cat_count?.STANDARD}/${dataFiltered?.count?.total}`}
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        <span className="card-text">STANDARD</span>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <b>STANDARD</b>
                              <>
                                <br />
                                <b>
                                  {dataFiltered?.count?.cat_count?.STANDARD ||
                                    0}
                                  /{dataFiltered?.count?.total ? 14450 : 0}
                                </b>
                              </>
                            </React.Fragment>
                          }
                        >
                          {" "}
                          <InfoIcon />
                        </Tooltip>
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                          className="card-text"
                        >
                          {dataFiltered?.count?.cat_count?.STANDARD || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                          className="card-text"
                        >
                          /{dataFiltered?.count?.total ? 14450 : 0 || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
                <Grid2 size={4}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        // title={`TECHNOLOGY :${dataFiltered?.count?.cat_count?.Technology}/${dataFiltered?.count?.total}`}
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <span className="card-text">TECHNOLOGY</span>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <b>TECHNOLOGY</b>
                              <>
                                <br />
                                <b>
                                  {dataFiltered?.count?.cat_count?.Technology ||
                                    0}
                                  /{dataFiltered?.count?.total ? 350 : 0}
                                </b>
                              </>
                            </React.Fragment>
                          }
                        >
                          {" "}
                          <InfoIcon />
                        </Tooltip>
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                          className="card-text"
                        >
                          {dataFiltered?.count?.cat_count?.Technology || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                          className="card-text"
                        >
                          /{dataFiltered?.count?.total ? 350 : 0 || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
                <Grid2 size={4}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                        // title={`SUB-TECHNOLOGY :${dataFiltered?.count?.cat_count?.Sub_Technology}/${dataFiltered?.count?.total}`}
                      >
                        <span className="card-text">SUB-TECHNOLOGY</span>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <b>SUB-TECHNOLOGY</b>
                              <>
                                <br />
                                <b>
                                  {dataFiltered?.count?.cat_count
                                    ?.Sub_Technology || 0}
                                  /{dataFiltered?.count?.total ? 3200 : 0}
                                </b>
                              </>
                            </React.Fragment>
                          }
                        >
                          {" "}
                          <InfoIcon />
                        </Tooltip>
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                          className="card-text"
                        >
                          {dataFiltered?.count?.cat_count?.Sub_Technology || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                          className="card-text"
                        >
                          /{dataFiltered?.count?.total ? 3200 : 0 || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
                <Grid2 size={4}>
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                        // title={`PUBLICATION NUMBER :${dataFiltered?.count?.cat_count?.Publication_Number}/${dataFiltered?.count?.total}`}
                      >
                        <span className="card-text">PUBLICATION NUMBER</span>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <b>PUBLICATION NUMBER</b>
                              <>
                                <br />
                                <b>
                                  {dataFiltered?.count?.cat_count
                                    ?.Publication_Number || 0}
                                  /{dataFiltered?.count?.total ? 169450 : 0}
                                </b>
                              </>
                            </React.Fragment>
                          }
                        >
                          {" "}
                          <InfoIcon />
                        </Tooltip>
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                          className="card-text"
                        >
                          {dataFiltered?.count?.cat_count?.Publication_Number ||
                            0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                          className="card-text"
                        >
                          /{dataFiltered?.count?.total ? 169450 : 0 || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                        // title={`PATENT OWNER :${dataFiltered?.count?.cat_count?.PATENT_OWNER}/${dataFiltered?.count?.total}`}
                      >
                        <span className="card-text">PATENT OWNER</span>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <b>PATENT OWNER</b>
                              <>
                                <br />
                                <b>
                                  {dataFiltered?.count?.cat_count
                                    ?.PATENT_OWNER || 0}
                                  /{dataFiltered?.count?.total ? 2500 : 0}
                                </b>
                              </>
                            </React.Fragment>
                          }
                        >
                          {" "}
                          <InfoIcon />
                        </Tooltip>
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                          className="card-text"
                        >
                          {dataFiltered?.count?.cat_count?.PATENT_OWNER || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                          className="card-text"
                        >
                          /{dataFiltered?.count?.total ? 2500 : 0 || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid2>
                <Grid2 size={4}>
                  {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Card
                    sx={{
                      flex: 1,
                      margin: 1,
                      // width: "254px",
                      // height: "94px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        sx={{
                          mb: 2,
                          ml: 2,
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineHeight: "28px",
                          fontSize: { xs: "14px", sm: "14px", md: "14px" },
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                        // title={`INVENTOR  :${dataFiltered?.count?.cat_count?.Inventor}/${dataFiltered?.count?.total}`}
                      >
                        <span className="card-text">INVENTOR</span>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <b>INVENTOR</b>
                              <>
                                <br />
                                <b>
                                  {dataFiltered?.count?.cat_count?.Inventor ||
                                    0}
                                  /{dataFiltered?.count?.total ? 63000 : 0}
                                </b>
                              </>
                            </React.Fragment>
                          }
                        >
                          {" "}
                          <InfoIcon />
                        </Tooltip>
                      </Typography>
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          fontSize={{ xs: "24px", sm: "24px", md: "24px" }}
                          component="span"
                          className="card-text"
                        >
                          {dataFiltered?.count?.cat_count?.Inventor || 0}
                        </Typography>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
                          component="span"
                          color="#999999"
                          className="card-text"
                        >
                          /{dataFiltered?.count?.total ? 63000 : 0 || 0}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* </Box> */}
                </Grid2>
              </Grid2>
            </Box>
          )}
          <Divider orientation="horizontal" sx={{ mb: 1, mt: 0 }} />
          <Grid2 container spacing={3}>
            <Grid2 size={4}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}
                >
                  IPRD Reference Number
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(IPRD_REFERENCE)]}
                  value={data.IPRD_REFERENCE || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      IPRD_REFERENCE: newValue,
                    }));
                  }}
                  loading={dataLoading}
                  onFocus={() => handleAutocompleteFocus("IPRD_REFERENCE")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "IPRD_REFERENCE", inputValue),
                  }}
                  onInputChange={(event, newInputValue) => {
                    // Trigger the API when user types in the input field
                    setInputValue(newInputValue);
                    setIPRD_REFERENCE([]);
                    handleInputKeywordSearch("IPRD_REFERENCE", newInputValue);
                  }}
                  onClose={() => {
                    setIPRD_REFERENCE([]);
                    setInputValue("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={4}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}
                >
                  Standard/ Specification
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(STANDARD)]}
                  value={data.STANDARD || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      STANDARD: newValue,
                    }));
                  }}
                  loading={dataLoading}
                  onFocus={() => handleAutocompleteFocus("RECOMMENDATION")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "RECOMMENDATION", inputValue), // Pass the event to handleScroll
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setSTANDARD([]);
                    handleInputKeywordSearch("RECOMMENDATION", newInputValue);
                  }}
                  onClose={() => {
                    setSTANDARD([]);
                    setInputValue("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={4}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}
                >
                  Sub-Technology
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(Sub_Technology)]}
                  value={data.Sub_Tech || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      Sub_Tech: newValue,
                    }));
                  }}
                  loading={dataLoading}
                  onFocus={() => handleAutocompleteFocus("Sub_Technology")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "Sub_Technology", inputValue), // Pass the event to handleScroll
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setSub_Technology([]);
                    handleInputKeywordSearch("Sub_Technology", newInputValue);
                  }}
                  onClose={() => {
                    setSub_Technology([]);
                    setInputValue("");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>

            <Grid2 size={4}>
              <Box sx={{ mb: 1, position: "relative" }}>
                <Typography
                  color="#333333"
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  fontWeight={600}
                >
                  Patent Number
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(Patent_Number)]}
                  loading={dataLoading}
                  value={data.PATENT_NUM || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      PATENT_NUM: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  onFocus={() => handleAutocompleteFocus("Patent_Number")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "Patent_Number", inputValue), // Pass the event to handleScroll
                  }}
                  onInputChange={(event, newInputValue) => {
                    setPATENT_Number([]);
                    setInputValue(newInputValue);
                    handleInputKeywordSearch("Patent_Number", newInputValue);
                  }}
                  onClose={() => {
                    setPATENT_Number([]);
                    setInputValue("");
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={4}>
              <Box sx={{ mb: 1, position: "relative" }}>
                <Typography
                  color="#333333"
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  fontWeight={600}
                >
                  Application Number
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(Application_Number)]}
                  loading={dataLoading}
                  value={data.Application_Number || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      Application_Number: newValue,
                    }));
                  }}
                  onFocus={() => handleAutocompleteFocus("Application_Number")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "Application_Number", inputValue), // Pass the event to handleScroll
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setApplication_Number([]);
                    handleInputKeywordSearch(
                      "Application_Number",
                      newInputValue
                    );
                  }}
                  onClose={() => {
                    setApplication_Number([]);
                    setInputValue([]);
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={4}>
              <Box sx={{ mb: 1, position: "relative" }}>
                <Typography
                  color="#333333"
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  fontWeight={600}
                >
                  Publication Number
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(Publication_Number)]}
                  loading={dataLoading}
                  value={data.Publication_Number || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      Publication_Number: newValue,
                    }));
                  }}
                  onFocus={() => handleAutocompleteFocus("Publication_Number")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "Publication_Number", inputValue), // Pass the event to handleScroll
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setPublication_Number([]);
                    handleInputKeywordSearch(
                      "Publication_Number",
                      newInputValue
                    );
                  }}
                  onClose={() => {
                    setPublication_Number([]);
                    setInputValue("");
                  }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}
                >
                  Patent Owner
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(PATENT_OWNER)]}
                  loading={dataLoading}
                  value={data.PATENT_OWNER || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      PATENT_OWNER: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  onFocus={() => handleAutocompleteFocus("PATENT_OWNER")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "PATENT_OWNER", inputValue), // Pass the event to handleScroll
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setPATENT_OWNER([]);
                    handleInputKeywordSearch("PATENT_OWNER", newInputValue);
                  }}
                  onClose={() => {
                    setPATENT_OWNER([]);
                    setInputValue("");
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}
                >
                  Inventor
                </Typography>
                <Autocomplete
                  multiple
                  options={[...new Set(Inventor)]}
                  loading={dataLoading}
                  value={data.Inventor || []}
                  onChange={(event, newValue) => {
                    setData((prevData) => ({
                      ...prevData,
                      Inventor: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      variant="outlined"
                      sx={{ fontSize: "14px" }}
                    />
                  )}
                  onFocus={() => handleAutocompleteFocus("Inventor")}
                  ListboxProps={{
                    onScroll: (event) =>
                      handleScroll(event, "Inventor", inputValue), // Pass the event to handleScroll
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setInventor([]);
                    handleInputKeywordSearch("Inventor", newInputValue);
                  }}
                  onClose={() => {
                    setInventor([]);
                    setInputValue("");
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={Math.random() * 10000} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  sx={{ fontSize: "14px", maxHeight: 300 }}
                />
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  color="#333333"
                  fontWeight={600}
                >
                  Declaration Date ( From )
                </Typography>
                <DatePicker
                  sx={{ width: "100%" }}
                  value={data.DATE_FROM}
                  onChange={handleDateChange("DATE_FROM")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  fontWeight={600}
                  color="#333333"
                >
                  Declaration Date ( To )
                </Typography>
                <DatePicker
                  sx={{ width: "100%" }}
                  value={data.DATE_TO}
                  onChange={handleDateChange("DATE_TO")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </Grid2>
          </Grid2>
        </CardContent>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F7C300",
              color: "#000",
              padding: "12px 24px",
              fontSize: "16px",
              textAlign: "center",
              fontWeight: 600,
              fontSize: { xs: "14px", sm: "14px", md: "14px" },
              "&:hover": {
                backgroundColor: "#FFE376",
              },
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            sx={{
              marginLeft: 2,
              padding: "12px 24px",
              fontWeight: 600,
              fontSize: { xs: "14px", sm: "14px", md: "14px" },
              textAlign: "center",
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Box>
      </Card>
      {tableListing === true && (
        <>
          <TableContainer
            component={Card}
            sx={{ maxWidth: "xl", margin: "auto", mt: 3, borderRadius: "12px" }}
          >
            <Table>
              <TableHeadRow headCells={headCells} />
              <TableBody>
                {isloading ? (
                  <TableRowsLoader rowsNum={10} columnNum={headCells.length} />
                ) : currentResults?.length > 0 ? (
                  currentResults?.map((item, index) => {
                    return (
                      <StyledTableRow key={`idx_${index}`}>
                        <StyledTableCell>
                          {item?.IPRD_REFERENCE}
                        </StyledTableCell>
                        <StyledTableCell>{item?.Type}</StyledTableCell>
                        <StyledTableCell>{item?.PATENT_OWNER}</StyledTableCell>
                        <StyledTableCell>
                          {item?.STANDARD_SETTING}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item?.Technology || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item?.IPRD_SIGNATURE_DATE}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            onClick={() =>
                              navigate(
                                `/viewdata/${item?.IPRD_REFERENCE}?dpig=${item.DIPG_DISPLAY_NUMBER}.1`,
                                { state: newQueryObject }
                              )
                            } // Handle the click to navigate
                            style={{
                              textDecoration: "none",
                              color: "#008DC4",
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                              fontSize: "inherit", // Inherit font size to match the table style
                            }}
                          >
                            View
                          </Button>
                          {/* <Link
                            href={`/viewdata/${item?.IPRD_REFERENCE}?dpig=${item.DIPG_DISPLAY_NUMBER}`}
                          style={{textDecoration:"none" ,color:"#008DC4"}}
                          >
                            View
                          </Link> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <StyledTableCell
                      style={{ textAlign: "center" }}
                      colSpan={7}
                      textAlign="center"
                      fontSize="14px"
                      fontWeight={500}
                    >
                      No Data Found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
            component="div"
            count={dataFiltered?.result?.length || 0} // Total count from filtered results
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}
    </LocalizationProvider>
  );
};
